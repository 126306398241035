import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from '../redux/store';
import {
  setFormFieldValue,
  startDocumentStatusesPolling,
  stopDocumentStatusesPolling,
} from '../redux/form/formActions';
import { NextButton } from './NextButton';
import { Field } from './Field';
import { BackButton } from './BackButton';
import { Chat } from './Chat';
import {
  canGoPrevStep,
  getCurrentStep,
  getCurrentStepBanners,
  getIsCurrentStepEmpty,
  getSteps,
  getTranslations,
  isFormLoading,
  useEnhancedDocumentCapture,
} from '../redux/form/formSelectors';
import { getMixpanel } from '../redux/mixpanel/mixpanelSelectors';
import { clearFieldError } from '../redux/notification/notificationActions';
import { checkShowField } from '../common/fieldConditions';
import MarkdownWrapper from './common/MarkdownWrapper';
import { trackEvent } from '../redux/common/commonThunk';
import { getDocumentsResults } from '../redux/form/formThunk';
import { UiEvent, UiPage, getEventNameByStepId } from '../common/events';
import { Loader } from './common/Loader';
import { getLanguage } from '../redux/localization/localizationSelectors';
import { useGetDocumentsResultsPolling } from '../hooks/useGetDocumentsResultsPolling';
import { SupportWidgetLoader } from './SupportWidgetLoader';
import { getFieldErrors } from '../redux/notification/notificationsSelectors';
import {
  autosaveHandleBlur,
  autosaveHandleBlurOnChangedValue,
  autosaveHandleUpdate,
} from '../redux/autosave/autosaveThunk';
import { AutosaveFieldStatus } from './AutosaveFieldStatus';
import { Banner } from './banners/Banner';

interface StepProps {
  hash: string;
}

function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(
    document.visibilityState !== 'hidden'
  );
  const onVisibilityChange = () => {
    setIsVisible(document.visibilityState !== 'hidden');
  };

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);
    window.addEventListener('focus', onVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      window.removeEventListener('focus', onVisibilityChange);
    };
  });

  return isVisible;
}

export const Step: React.FC<StepProps> = ({ hash }: StepProps) => {
  const dispatch = useDispatch();

  const step = useSelector(getCurrentStep);
  const banners = useSelector(getCurrentStepBanners);
  const steps = useSelector(getSteps);
  const isCurrentStepEmpty = useSelector(getIsCurrentStepEmpty);
  const isLoading = useSelector(isFormLoading);
  const commonTranslations = useSelector(getTranslations);
  const canGoBack = useSelector(canGoPrevStep);
  const language = useSelector(getLanguage);
  const mixpanel = useSelector(getMixpanel);
  const [isEventSent, setIsEventSent] = useState(false);
  const useEnhancedCapture = useSelector(useEnhancedDocumentCapture);
  const hasValidationErrors = useSelector(getFieldErrors).length > 0;

  useGetDocumentsResultsPolling();

  const usePolling: boolean =
    useEnhancedCapture && !!step?.fields.find((f) => f.document_type_key);

  const isVisible = usePageVisibility();

  const fieldAutosaveBlurHandler = (
    config: {
      enabled: boolean;
      always?: boolean;
    },
    field: DriverRegistrationPortalNoAuthService.Field
  ) => {
    if (!config.enabled) {
      return;
    }
    if (config.always) {
      dispatch(autosaveHandleBlur(field));
    } else {
      dispatch(autosaveHandleBlurOnChangedValue());
    }
  };

  useEffect(() => {
    if (usePolling) {
      if (isVisible) {
        dispatch(getDocumentsResults());
      }
    }
  }, [isVisible, dispatch, usePolling]);

  useEffect(() => {
    if (usePolling) {
      dispatch(startDocumentStatusesPolling());
    }
    return () => {
      dispatch(stopDocumentStatusesPolling());
    };
  }, [dispatch, usePolling, step?.id]);

  useEffect(() => {
    if (
      !isEventSent &&
      step?.id ===
        DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep
          .STEP2
    ) {
      setIsEventSent(true);
      dispatch(trackEvent(UiPage.DRP_STEP_2, UiEvent.PAGE_RENDERED));
    }
  }, [dispatch, step, isEventSent]);

  useEffect(() => {
    if (!step?.id || !mixpanel) return;
    const eventName = getEventNameByStepId(step.id);
    mixpanel.trackPageLoaded(eventName);
  }, [step?.id, mixpanel]);

  if (isLoading) {
    return <div className="p-4">Loading...</div>;
  }

  if (!step) {
    return <></>;
  }

  const mappedBanners = banners.length
    ? banners.map((banner) => {
        return <Banner bannerData={banner} key={banner.title_html} />;
      })
    : [];

  const mappedFields = step.fields
    .map((field, index) => {
      const showField = checkShowField(field, steps);
      let key: string;
      if (
        field.type ===
        DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
          .DOCUMENT_UPLOAD
      ) {
        if (field.document_type_key) {
          key = field.document_type_key;
        } else {
          key = field.name + index;
        }
      } else if (field.generic_field_key) {
        key = field.generic_field_key;
      } else {
        key = field.name;
      }

      return showField ? (
        <Field
          fieldDefinition={field}
          commonTranslations={commonTranslations}
          step={step}
          hash={hash}
          updateHandler={({ changed, autosaveEnabled }) => {
            dispatch(setFormFieldValue(step, field, changed));
            dispatch(clearFieldError(key));
            if (autosaveEnabled) {
              dispatch(autosaveHandleUpdate(field, changed));
            }
          }}
          blurHandler={(_e, autosave) =>
            fieldAutosaveBlurHandler(autosave, field)
          }
          key={key}
        />
      ) : null;
    })
    .filter((f) => !!f);

  if (isCurrentStepEmpty) {
    return (
      <div className="text-left rtl:text-right tablet:text-center tablet:rtl:text-center mx-auto mt-[-12]">
        <Loader
          data-test="app_form_loader"
          textColor="text-green-500"
          id="document_upload_example_loader"
        />
      </div>
    );
  }

  return (
    <div>
      <SupportWidgetLoader
        step={step.id}
        hasValidationErrors={hasValidationErrors}
      />
      <AutosaveFieldStatus commonTranslations={commonTranslations} />
      <div className="text-left rtl:text-right tablet:text-center tablet:rtl:text-center mx-auto">
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>{step.translations.title}</MarkdownWrapper>
        </h1>
        {step.translations.subtitle && (
          <div className="text-gray-700 text-sm mt-1">
            <MarkdownWrapper>{step.translations.subtitle}</MarkdownWrapper>
          </div>
        )}
      </div>
      {mappedBanners.length > 0 && (
        <div className="flex flex-col content-center justify-center pt-6 pb-4 mx-auto">
          {mappedBanners}
        </div>
      )}
      <div className="flex flex-col content-center justify-center pt-6 mx-auto">
        {mappedFields}
        <div className="flex justify-center flex-row-reverse flex-wrap">
          <div className="mx-2">
            <NextButton
              step={step}
              text={commonTranslations.document_upload.next_button_label}
            />
          </div>
          {canGoBack ? (
            <div className="mx-2">
              <BackButton
                text={commonTranslations.document_upload.back_button_label}
              />
            </div>
          ) : null}
        </div>
        <Chat hash={hash} language={language} />
      </div>
    </div>
  );
};
