var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
//Generated by: TsAppClientApiPrinter
//Contact #dev-ui-platform-support in case of issues
import { GeneratedApiClient } from "../ApiClient";
import { HttpMethod } from "../HttpClient";
/* tslint:disable:no-namespace */
export var DriverRegistrationPortalNoAuthService;
(function (DriverRegistrationPortalNoAuthService) {
    var ApiClientClass = /** @class */ (function (_super) {
        __extends(ApiClientClass, _super);
        function ApiClientClass() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
            _this.getFields = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getFields",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
            _this.getTaxIdentificationNumberFormats = function (opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getTaxIdentificationNumberFormats",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            }
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
            _this.getVehicleMakes = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getVehicleMakes",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
            _this.getVehicleModelsByManufacturer = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getVehicleModelsByManufacturer",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
            _this.getSupportedLanguages = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getSupportedLanguages",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
            _this.getCommonTranslations = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getCommonTranslations",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.getDriverRegistration = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getDriverRegistration",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.signup = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/signup",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.autosave = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/autosave",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.validateDriverRegistrationDocumentsForResubmission = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/validateDriverRegistrationDocumentsForResubmission",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
            _this.startRegistration = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/startRegistration",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
            _this.startVerification = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/startVerification",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
            _this.confirmVerification = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/confirmVerification",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
            _this.createAccount = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/createAccount",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
            _this.getDocumentsResults = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getDocumentsResults",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
            _this.getDocumentCaptureMode = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getDocumentCaptureMode",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
            _this.getExperimentParameters = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getExperimentParameters",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
            _this.getDriverVisitorExperiments = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getDriverVisitorExperiments",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
            _this.getExperimentParametersBeforeSignup = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getExperimentParametersBeforeSignup",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.getDriverRegistrationStatus = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getDriverRegistrationStatus",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.getAllCountries = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getAllCountries",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.getFormDataForRiderUser = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getFormDataForRiderUser",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
            _this.startRegistrationForRiderUser = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/startRegistrationForRiderUser",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.searchAddress = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/searchAddress",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.getAddressDetails = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/getAddressDetails",
                            service_id: "driver-registration-portal",
                            subdomain: "driverregistration",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            return _this;
        }
        return ApiClientClass;
    }(GeneratedApiClient));
    // Split class actual prototype and type
    DriverRegistrationPortalNoAuthService.ApiClient = ApiClientClass;
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    var AppPromotionMode;
    (function (AppPromotionMode) {
        AppPromotionMode["NONE"] = "none";
        AppPromotionMode["OPTIONAL"] = "optional";
        AppPromotionMode["REQUIRED"] = "required";
    })(AppPromotionMode = DriverRegistrationPortalNoAuthService.AppPromotionMode || (DriverRegistrationPortalNoAuthService.AppPromotionMode = {}));
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    var BannerActionType;
    (function (BannerActionType) {
        BannerActionType["NONE"] = "none";
        BannerActionType["REFRESH_PAGE"] = "refresh_page";
    })(BannerActionType = DriverRegistrationPortalNoAuthService.BannerActionType || (DriverRegistrationPortalNoAuthService.BannerActionType = {}));
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    var BannerType;
    (function (BannerType) {
        BannerType["ICON_BANNER"] = "icon_banner";
        BannerType["COLORED_ICON_BANNER"] = "colored_icon_banner";
    })(BannerType = DriverRegistrationPortalNoAuthService.BannerType || (DriverRegistrationPortalNoAuthService.BannerType = {}));
    /** Generated from: {@link [BrandHelper.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/util/brand/BrandHelper.ts)} */
    var BrandEnum;
    (function (BrandEnum) {
        BrandEnum["BOLT"] = "bolt";
        BrandEnum["HOPP"] = "hopp";
    })(BrandEnum = DriverRegistrationPortalNoAuthService.BrandEnum || (DriverRegistrationPortalNoAuthService.BrandEnum = {}));
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    var CaptchaChallengeType;
    (function (CaptchaChallengeType) {
        CaptchaChallengeType["RECAPTCHA_NATIVE"] = "recaptcha_native";
        CaptchaChallengeType["WEBVIEW"] = "webview";
    })(CaptchaChallengeType = DriverRegistrationPortalNoAuthService.CaptchaChallengeType || (DriverRegistrationPortalNoAuthService.CaptchaChallengeType = {}));
    /** Generated from: {@link [FleetCarDataInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/asyncApi/fleet/FleetCarDataInternalAsyncApi.ts)} */
    var CarModelVehicleType;
    (function (CarModelVehicleType) {
        CarModelVehicleType["CAR"] = "car";
        CarModelVehicleType["MOTORBIKE"] = "motorbike";
    })(CarModelVehicleType = DriverRegistrationPortalNoAuthService.CarModelVehicleType || (DriverRegistrationPortalNoAuthService.CarModelVehicleType = {}));
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/FlowData.ts)} */
    var ConditionType;
    (function (ConditionType) {
        ConditionType["NONE"] = "none";
        ConditionType["DEFAULT"] = "default";
        ConditionType["CHECKBOX"] = "checkbox";
        ConditionType["SELECT"] = "select";
        ConditionType["DOCUMENT_UPLOAD"] = "document_upload";
        ConditionType["NUMERIC"] = "numeric";
        ConditionType["GENERIC_DROPDOWN"] = "generic_dropdown";
        ConditionType["GENERIC_CHECKBOX"] = "generic_checkbox";
    })(ConditionType = DriverRegistrationPortalNoAuthService.ConditionType || (DriverRegistrationPortalNoAuthService.ConditionType = {}));
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/common/constants/Types.ts)} */
    var DocumentResultStatus;
    (function (DocumentResultStatus) {
        DocumentResultStatus["NOT_UPLOADED"] = "not_uploaded";
        DocumentResultStatus["IN_REVIEW"] = "in_review";
        DocumentResultStatus["CONFIRMED"] = "confirmed";
        DocumentResultStatus["DECLINED"] = "declined";
        DocumentResultStatus["MANUALLY_UPLOADED"] = "manually_uploaded";
    })(DocumentResultStatus = DriverRegistrationPortalNoAuthService.DocumentResultStatus || (DriverRegistrationPortalNoAuthService.DocumentResultStatus = {}));
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    var DriverRegistrationFormOfWork;
    (function (DriverRegistrationFormOfWork) {
        DriverRegistrationFormOfWork["LICENSE_OWNER"] = "license_owner";
        DriverRegistrationFormOfWork["EMPLOYEE_OF_A_FLEET"] = "employee_of_a_fleet";
    })(DriverRegistrationFormOfWork = DriverRegistrationPortalNoAuthService.DriverRegistrationFormOfWork || (DriverRegistrationPortalNoAuthService.DriverRegistrationFormOfWork = {}));
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    var DriverRegistrationLogLastStep;
    (function (DriverRegistrationLogLastStep) {
        DriverRegistrationLogLastStep["STEP1"] = "step_1";
        DriverRegistrationLogLastStep["STEP2"] = "step_2";
        DriverRegistrationLogLastStep["STEP3"] = "step_3";
        DriverRegistrationLogLastStep["STEP4"] = "step_4";
        DriverRegistrationLogLastStep["STEP5"] = "step_5";
        DriverRegistrationLogLastStep["STEP6"] = "step_6";
    })(DriverRegistrationLogLastStep = DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep || (DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep = {}));
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    var DriverRegistrationLogLicenseTypeSelection;
    (function (DriverRegistrationLogLicenseTypeSelection) {
        DriverRegistrationLogLicenseTypeSelection["PRIVATE_DRIVER"] = "private_driver";
        DriverRegistrationLogLicenseTypeSelection["TAXI_DRIVER"] = "taxi_driver";
        DriverRegistrationLogLicenseTypeSelection["UNKNOWN"] = "unknown";
    })(DriverRegistrationLogLicenseTypeSelection = DriverRegistrationPortalNoAuthService.DriverRegistrationLogLicenseTypeSelection || (DriverRegistrationPortalNoAuthService.DriverRegistrationLogLicenseTypeSelection = {}));
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/driver/constants/Types.ts)} */
    var DriverRegistrationLogStatus;
    (function (DriverRegistrationLogStatus) {
        DriverRegistrationLogStatus["IN_PROGRESS"] = "in_progress";
        DriverRegistrationLogStatus["SUBMITTED"] = "submitted";
        DriverRegistrationLogStatus["ONBOARDING"] = "onboarding";
        DriverRegistrationLogStatus["DECLINED"] = "declined";
        DriverRegistrationLogStatus["DECLINED_SILENTLY"] = "declined_silently";
        DriverRegistrationLogStatus["ACCEPTED"] = "accepted";
        DriverRegistrationLogStatus["DOCUMENTS_NEED_RESUBMISSION"] = "documents_need_resubmission";
        DriverRegistrationLogStatus["DOCUMENTS_RESUBMITTED"] = "documents_resubmitted";
        DriverRegistrationLogStatus["DELETED"] = "deleted";
        DriverRegistrationLogStatus["PENDING_COMPANY_APPROVAL"] = "pending_company_approval";
    })(DriverRegistrationLogStatus = DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus || (DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus = {}));
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    var DriverRegistrationLogType;
    (function (DriverRegistrationLogType) {
        DriverRegistrationLogType["COMPANY"] = "company";
        DriverRegistrationLogType["PERSON"] = "person";
        DriverRegistrationLogType["OTHER"] = "other";
    })(DriverRegistrationLogType = DriverRegistrationPortalNoAuthService.DriverRegistrationLogType || (DriverRegistrationPortalNoAuthService.DriverRegistrationLogType = {}));
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/FieldDefinitionBase.ts)} */
    var FrontendFieldDefinitionType;
    (function (FrontendFieldDefinitionType) {
        FrontendFieldDefinitionType["INPUT"] = "input";
        FrontendFieldDefinitionType["SELECT"] = "select";
        FrontendFieldDefinitionType["CHECKBOX"] = "checkbox";
        FrontendFieldDefinitionType["DATE"] = "date";
        FrontendFieldDefinitionType["DOCUMENT_UPLOAD"] = "document_upload";
        FrontendFieldDefinitionType["TEXT"] = "text";
        FrontendFieldDefinitionType["GENERIC_INPUT"] = "generic_input";
        FrontendFieldDefinitionType["GENERIC_DROPDOWN"] = "generic_dropdown";
        FrontendFieldDefinitionType["TAX_IDENTIFICATION_NUMBERS"] = "tax_identification_numbers";
        FrontendFieldDefinitionType["HIDDEN"] = "hidden";
        FrontendFieldDefinitionType["EMBEDDED_RESOURCE"] = "embedded_resource";
        FrontendFieldDefinitionType["LINK_BUTTON"] = "link_button";
        FrontendFieldDefinitionType["GENERIC_CHECKBOX"] = "generic_checkbox";
    })(FrontendFieldDefinitionType = DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType || (DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType = {}));
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    var Gender;
    (function (Gender) {
        Gender["UNSPECIFIED"] = "unspecified";
        Gender["MALE"] = "male";
        Gender["FEMALE"] = "female";
        Gender["OTHER"] = "other";
    })(Gender = DriverRegistrationPortalNoAuthService.Gender || (DriverRegistrationPortalNoAuthService.Gender = {}));
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/FlowData.ts)} */
    var GenericDropdownConditionOperationType;
    (function (GenericDropdownConditionOperationType) {
        GenericDropdownConditionOperationType["EQUAL"] = "equal";
        GenericDropdownConditionOperationType["EMPTY"] = "empty";
        GenericDropdownConditionOperationType["NOT_EMPTY"] = "not_empty";
    })(GenericDropdownConditionOperationType = DriverRegistrationPortalNoAuthService.GenericDropdownConditionOperationType || (DriverRegistrationPortalNoAuthService.GenericDropdownConditionOperationType = {}));
    /** Generated from: {@link [FlowHelper.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/common/helper/FlowHelper.ts)} */
    var ImageSubject;
    (function (ImageSubject) {
        ImageSubject["TEXT_DOCUMENT"] = "text_document";
        ImageSubject["FACE_CLOSEUP"] = "face_closeup";
        ImageSubject["OTHER_PHOTOGRAPH"] = "other_photograph";
    })(ImageSubject = DriverRegistrationPortalNoAuthService.ImageSubject || (DriverRegistrationPortalNoAuthService.ImageSubject = {}));
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/FlowData.ts)} */
    var NumericConditionOperationType;
    (function (NumericConditionOperationType) {
        NumericConditionOperationType["EQUAL"] = "equal";
        NumericConditionOperationType["NOT_EQUAL"] = "not_equal";
        NumericConditionOperationType["LESS"] = "less";
        NumericConditionOperationType["MORE"] = "more";
        NumericConditionOperationType["LESS_OR_EQUAL"] = "less_or_equal";
        NumericConditionOperationType["MORE_OR_EQUAL"] = "more_or_equal";
    })(NumericConditionOperationType = DriverRegistrationPortalNoAuthService.NumericConditionOperationType || (DriverRegistrationPortalNoAuthService.NumericConditionOperationType = {}));
    /** Generated from: {@link [DynamicLanguageConfig.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/config/DynamicLanguageConfig.ts)} */
    var ScriptDirection;
    (function (ScriptDirection) {
        ScriptDirection["LTR"] = "ltr";
        ScriptDirection["RTL"] = "rtl";
    })(ScriptDirection = DriverRegistrationPortalNoAuthService.ScriptDirection || (DriverRegistrationPortalNoAuthService.ScriptDirection = {}));
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/FlowData.ts)} */
    var UploadStatus;
    (function (UploadStatus) {
        UploadStatus["NOT_UPLOADED"] = "not_uploaded";
        UploadStatus["IN_MODAL_DIALOG"] = "in_modal_dialog";
        UploadStatus["UPLOADED"] = "uploaded";
    })(UploadStatus = DriverRegistrationPortalNoAuthService.UploadStatus || (DriverRegistrationPortalNoAuthService.UploadStatus = {}));
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/common/flow_form_builder/FieldDefinitionBase.ts)} */
    var VehicleTypeModeSelection;
    (function (VehicleTypeModeSelection) {
        VehicleTypeModeSelection["HIDDEN"] = "hidden";
        VehicleTypeModeSelection["USER_SELECT"] = "user_select";
        VehicleTypeModeSelection["MOTORBIKES_ONLY"] = "motorbikes_only";
        VehicleTypeModeSelection["CARS_ONLY"] = "cars_only";
    })(VehicleTypeModeSelection = DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection || (DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection = {}));
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/1f5f0d8f48c43cb81f4e14e00e40512bc50a52d3/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    var VerificationCodeChannel;
    (function (VerificationCodeChannel) {
        VerificationCodeChannel["SMS"] = "sms";
        VerificationCodeChannel["CALL"] = "call";
        VerificationCodeChannel["WHATSAPP"] = "whatsapp";
    })(VerificationCodeChannel = DriverRegistrationPortalNoAuthService.VerificationCodeChannel || (DriverRegistrationPortalNoAuthService.VerificationCodeChannel = {}));
})(DriverRegistrationPortalNoAuthService || (DriverRegistrationPortalNoAuthService = {}));
