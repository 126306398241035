import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { SignupButton } from './SignupButton';
import { CityDropdown } from './CityDropdown';
import {
  getIsDataLoading,
  getRiderUserSignupData,
} from '../../redux/rider_user_signup/riderUserSignupSelectors';
import { initLanguages } from '../../redux/localization/localizationThunk';
import { loadSignupForm } from '../../redux/rider_user_signup/riderUserThunk';
import { Loader } from '../common/Loader';
import MarkdownWrapper from '../common/MarkdownWrapper';
import emailIcon from '../../assets/email.svg';
import phoneIcon from '../../assets/phone.svg';
import { Button } from '../Button';
import { getSignupLightweightLandingLink } from '../../common/helpers';
import {
  getCountry,
  getLanguage,
} from '../../redux/localization/localizationSelectors';

export const RiderUserSignup: React.FC = () => {
  const dispatch = useDispatch();
  const {
    termsAndConditionsText,
    title,
    subTitle,
    email,
    phone,
    useAnotherPhoneEmailButtonLabel,
    reviewContactDetailsText,
    continueButtonLabel,
  } = useSelector(getRiderUserSignupData);
  const isLoading = useSelector(getIsDataLoading);
  const languageCode = useSelector(getLanguage);
  const countryCode = useSelector(getCountry);
  const [areCredentialsConfirmed, setAreCredentialsConfirmed] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      // Load languages should be called before loadSignupForm for proper redirection
      await dispatch(initLanguages());
      await dispatch(loadSignupForm());
    };

    loadData();
  }, [dispatch]);

  const goToLanding = useCallback(() => {
    const signupLightweightLandingLink = getSignupLightweightLandingLink(
      languageCode,
      countryCode
    );
    window.location.replace(signupLightweightLandingLink);
  }, [countryCode, languageCode]);

  const continueRegistration = useCallback(() => {
    setAreCredentialsConfirmed(true);
  }, [setAreCredentialsConfirmed]);

  if (isLoading) {
    return (
      <div className="clearfix pt-6">
        <div className="flex flex-row justify-center my-8 h-24">
          <Loader
            data-test="app_form_loader"
            textColor="text-green-500"
            id="rider_sign_up_loader"
          />
        </div>
      </div>
    );
  }

  if (!areCredentialsConfirmed) {
    return (
      <div className="flex flex-col pt-6">
        <div className="flex flex-col mb-8">
          <h1 className="text-2xl font-bold">
            <MarkdownWrapper>{title ?? ''}</MarkdownWrapper>
          </h1>
          <div className="mt-2 text-gray-700">
            <MarkdownWrapper>{reviewContactDetailsText}</MarkdownWrapper>
          </div>
        </div>

        <div className="flex border-b border-gray-300 pb-4 mb-4">
          <div className="flex-none w-10">
            <img src={phoneIcon} alt="Phone" width={24} height={24} />
          </div>
          <div className="flex-auto">{phone}</div>
        </div>

        <div className="flex">
          <div className="flex-none w-10">
            <img src={emailIcon} alt="E-mail" width={24} height={24} />
          </div>
          <div className="flex-auto">{email}</div>
        </div>

        <div className="mt-6 mb-2">
          <Button
            onClick={continueRegistration}
            color="bg-green-500"
            label={continueButtonLabel}
            id="continue_button"
            disabled={false}
            loading={false}
          />
        </div>
        <div>
          <Button
            onClick={goToLanding}
            color="bg-gray-200"
            textColor="text-gray-900"
            label={useAnotherPhoneEmailButtonLabel}
            id="change_button"
            disabled={false}
            loading={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col pt-6 gap-6">
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>{title ?? ''}</MarkdownWrapper>
        </h1>
        <div className="mt-1 text-gray-700">
          <MarkdownWrapper>{subTitle ?? ''}</MarkdownWrapper>
        </div>
      </div>

      <div className="flex flex-col pt-1 space-y-2">
        <CityDropdown />
      </div>
      <div className="text-center text-gray-700 leading-4 text-xs font-normal">
        <MarkdownWrapper>{termsAndConditionsText ?? ''}</MarkdownWrapper>
      </div>
      <div className="flex justify-center flex-row-reverse flex-wrap">
        <SignupButton />
      </div>
    </div>
  );
};
