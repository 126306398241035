import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { IAppState, RiderUserSignupData } from '../types';

export const getRiderUserSignupData = (
  state: IAppState
): RiderUserSignupData => ({
  email: state.riderUserSignup.formData.email,
  phone: state.riderUserSignup.formData.phone,
  title: state.riderUserSignup.formData.title,
  subTitle: state.riderUserSignup.formData.sub_title,
  countryLabel: state.riderUserSignup.formData.country_label,
  cityLabel: state.riderUserSignup.formData.city_label,
  termsAndConditionsText:
    state.riderUserSignup.formData.terms_and_conditions_text,
  registerButtonLabel: state.riderUserSignup.formData.register_button_label,
  continueButtonLabel:
    state.riderUserSignup.formData.continue_registration_button_label,
  useAnotherPhoneEmailButtonLabel:
    state.riderUserSignup.formData.use_another_phone_email_button_label,
  reviewContactDetailsText:
    state.riderUserSignup.formData.review_contact_details_text,
});

export const getCurrentCity = (state: IAppState) => {
  return state.riderUserSignup.city !== undefined
    ? state.riderUserSignup.city
    : state.riderUserSignup.formData.city_id_by_ip ?? null;
};

export const getIsFormUploading = (state: IAppState): boolean =>
  state.riderUserSignup.uploading;

export const getIsDataLoading = (state: IAppState): boolean =>
  state.riderUserSignup.loading;

export const getCountryOptions = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.SelectValueItem[] =>
  state.riderUserSignup.formData.cities_by_countries?.map((v) => {
    return {
      value: v.code,
      title: v.name,
      subitems: v.cities.map((c) => ({
        value: c.id,
        title: c.local_name || c.name,
      })),
    };
  });
