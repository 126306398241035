import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { replace } from 'connected-react-router';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FleetRegistrationNoAuthService } from '@bolteu/bolt-server-api-driver-portal';
import { v4 as uuidv4 } from 'uuid';
import {
  handleErrorResponse,
  handleFieldValidationErrors,
} from '../../api/ApiErrorsHandler';
import * as LocalStorage from '../../common/LocalStorageService';
import {
  formLoaded,
  formUploaded,
  formUploading,
  setDocumentStatuses,
  setRegistrationStatus,
} from './formActions';
import { getHash, getTranslations } from './formSelectors';
import { IAppState, ThunkMiddlware } from '../types';
import { clearErrors } from '../notification/notificationActions';
import { GoogleAnalytics } from '../../common/googleAnalytics';
import { loadFormData, redirectToFinalScreen } from '../common/commonHelpers';
import { getLanguage } from '../localization/localizationSelectors';
import partnerDriverApiClient from '../../api/PartnerRegistrationApi';
import { buildPostFormPayload } from './formHelpers';
import { autosaveSuccessful } from '../autosave/autosaveActions';
import { getAppVersion } from '../../common/helpers';
import { Logger } from '../../common/logger';

export const reloadFormData: ActionCreator<
  ThunkAction<
    Promise<void>,
    IAppState,
    ThunkMiddlware,
    Action<typeof formLoaded>
  >
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  Logger.log(`reloadFormData`);
  try {
    await loadFormData(dispatch, getState);
  } catch (e) {
    handleErrorResponse(e, dispatch);
  }
};

export const performStatusPolling: ActionCreator<
  ThunkAction<
    Promise<void>,
    IAppState,
    ThunkMiddlware,
    Action<typeof reloadFormData>
  >
> =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ): Promise<void> => {
    Logger.log(`performStatusPolling`);
    const state = getState();
    const hash = getHash(state);
    try {
      const response = await registrationApi.getDriverRegistrationStatus({
        hash,
      });
      dispatch(
        setRegistrationStatus(response.status, response.decline_reasons)
      );
      if (
        response.status ===
        DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
          .DOCUMENTS_NEED_RESUBMISSION
      ) {
        dispatch(reloadFormData());
        dispatch(replace(`/driver/${window.location.search}`));
      }
    } catch (e) {
      Logger.log(`performStatusPolling - API failed`, e as Error);
    }
  };

export const postForm: ActionCreator<
  ThunkAction<
    Promise<void>,
    IAppState,
    ThunkMiddlware,
    Action<typeof reloadFormData>
  >
> =
  (step: DriverRegistrationPortalNoAuthService.Step, skipEmptyStep = false) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    Logger.log(`postForm`);
    try {
      if (skipEmptyStep) {
        Logger.log(`postForm - with skipEmptyStep`);
        // Wait a bit to avoid hitting rate limiter in case we skip more than 1 step
        await new Promise((res) => setTimeout(res, 500));
      }

      dispatch(clearErrors());
      dispatch(formUploading());

      const state = getState();
      const language = getLanguage(state);
      const form = state.form;
      const hash = getHash(state);
      const timestampInSeconds = Math.floor(Date.now() / 1000);
      const messageId = `${uuidv4()}driver${timestampInSeconds}`;
      const appVersion = getAppVersion();

      const payload = buildPostFormPayload(step, form.current_step);
      try {
        await registrationApi.signup(
          {
            hash,
            language,
          },
          {
            ...payload,
            web_marketing_data: {
              ...payload.web_marketing_data,
              message_id: messageId,
            },
            ...(appVersion && { app_version: appVersion }),
          }
        );

        if (payload.signup_data.referral_code) {
          LocalStorage.setItem(
            LocalStorage.AwinDiscountCode,
            payload.signup_data.referral_code
          );
        }

        GoogleAnalytics.onStepSubmit(
          form.current_step,
          form.flow_id,
          hash,
          messageId
        );

        dispatch(autosaveSuccessful());

        dispatch(reloadFormData());
      } catch (e) {
        Logger.log(`postForm - API call failed`, e as Error);
        handleFieldValidationErrors(e, dispatch);
      }
    } finally {
      dispatch(formUploaded());
      const notificationContainer = document.getElementById(
        'notification-container'
      );
      const validationHints = document.getElementsByClassName('error-hint');
      if (notificationContainer) {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      } else if (validationHints.length) {
        const validationHint = validationHints[0];
        const headerOffset = 90;
        const elementPosition = validationHint.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

export const deleteDocument: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> =
  (hash: string, documentId: number) =>
  async (dispatch: Dispatch<any>, _: () => IAppState, { documentsApi }) => {
    try {
      return await documentsApi.deleteDriverRegistrationDocument({
        hash,
        document_id: documentId,
      });
    } catch (e) {
      handleErrorResponse(e, dispatch);
      return Promise.reject(e);
    }
  };

export const uploadDocument: ActionCreator<
  ThunkAction<
    Promise<FleetRegistrationNoAuthService.FleetUploadedDocument>,
    IAppState,
    ThunkMiddlware,
    Action<void>
  >
> =
  (
    expires: string | undefined,
    file: { [x: string]: FleetRegistrationNoAuthService.File }
  ) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { documentsApi }
  ) => {
    const state = getState();
    const hash = getHash(state);
    const language = getLanguage(state);
    try {
      return await documentsApi.uploadDriverRegistrationDocument(
        {
          hash,
          language,
          ...(expires && { expires }),
        },
        file
      );
    } catch (e) {
      const unknownErrorTranslation =
        getTranslations(state).document_upload.unknown_error;
      handleErrorResponse(e, dispatch, unknownErrorTranslation);
      return Promise.reject(e);
    }
  };

export const validateDocumentsResubmission: (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
  middleware: ThunkMiddlware
) => Promise<void> = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
  { registrationApi }
) => {
  const state = getState();
  const hash = getHash(state);
  const language = getLanguage(state);

  dispatch(formUploading());

  try {
    await registrationApi.validateDriverRegistrationDocumentsForResubmission(
      { hash, language },
      {}
    );
    dispatch(
      setRegistrationStatus(
        DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
          .DOCUMENTS_RESUBMITTED
      )
    );
    await redirectToFinalScreen(dispatch, getState);
  } catch (e) {
    handleFieldValidationErrors(e, dispatch);
  } finally {
    dispatch(formUploaded());
  }
};

export const getDocumentsResults: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action>
> =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    const hash = getHash(getState());
    const language = getLanguage(getState());

    try {
      const response = await registrationApi.getDocumentsResults({
        hash,
        language,
      });
      dispatch(setDocumentStatuses(response.documents));
    } catch (e) {
      // ignore
    }
  };

export const fallbackDocumentUploadForEnhancedCapture: ActionCreator<
  ThunkAction<
    Promise<FleetRegistrationNoAuthService.FleetUploadedDocument>,
    IAppState,
    ThunkMiddlware,
    Action<void>
  >
> =
  (
    expires: string | undefined,
    file: { [x: string]: FleetRegistrationNoAuthService.File }
  ) =>
  async (dispatch: Dispatch<any>, getState: () => IAppState) => {
    const state = getState();
    const hash = getHash(state);
    try {
      const result =
        await partnerDriverApiClient.uploadDriverRegistrationDocument(
          {
            hash,
            ...(expires && { expires }),
          },
          file
        );
      dispatch(getDocumentsResults());
      return result;
    } catch (e) {
      const unknownErrorTranslation =
        getTranslations(state).document_upload.unknown_error;
      handleErrorResponse(e, dispatch, unknownErrorTranslation);
      return Promise.reject(e);
    }
  };
