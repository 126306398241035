import { getCurrentEnvironment, Environment } from './environment';

// eslint-disable-next-line @typescript-eslint/ban-types
type anyType = object | string | number | boolean | null;

export const Logger = {
  log(message: string, obj?: anyType): void {
    if (!Logger.isLoggingEnabled()) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(message, obj);
  },

  isLoggingEnabled(): boolean {
    if (localStorage.getItem('isLoggerEnabled') === '1') {
      return true;
    }
    const env = getCurrentEnvironment();
    return env !== Environment.LIVE;
  },
};
