import { Dispatch } from 'redux';
import { replace } from 'connected-react-router';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import partnerRegistrationApi from '../../api/PartnerRegistrationApi';
import registrationApi from '../../api/DriverRegistrationApi';
import {
  getAppVersionFromQuery,
  getCountryFromQuery,
  getLegacySignupLink,
} from '../../common/helpers';
import * as LocalStorage from '../../common/LocalStorageService';
import {
  formLoaded,
  setCaptureModeLoaded,
  setRegistrationStatus,
} from '../form/formActions';
import {
  getCurrentStep,
  getFinalScreen,
  getHash,
  getIsCurrentStepEmpty,
  getRegistrationStatus,
} from '../form/formSelectors';
import { IAppState, LanguageLoadingStatus, LoadingStatus } from '../types';
import { GoogleAnalytics } from '../../common/googleAnalytics';
import { setIsRedirecting, setLoadingStatus } from '../auth/authActions';
import { getHasPartnerAccount } from '../auth/authSelectors';
import {
  handleErrorResponse,
  isErrorResponse,
} from '../../api/ApiErrorsHandler';
import { setCookie } from '../../common/cookies';
import {
  getCountry,
  getIsCountryFinal,
  getLanguage,
} from '../localization/localizationSelectors';
import {
  setCommonTranslations,
  setCountry,
  setIsCountryFinal,
  setLanguage,
  setLanguageLoadingStatus,
  setLanguages,
} from '../localization/localizationActions';
import {
  getMockCommonTranslations,
  getMockSupportedLanguagesResponse,
} from '../../api/Mocks';
import geoApiClient from '../../api/GeoApi';
import { Logger } from '../../common/logger';
import { ResponseCodes } from '../../api/ResponseCodes';
import {
  ALLOWED_STATUSES,
  DECLINED_STATUSES,
  FINAL_STATUSES,
  SUBMITTED_STATUSES,
} from '../../const/statuses';
import { DEFAULT_COUNTRY } from '../../const/defaults';
import { postForm } from '../form/formThunk';

export const redirectToFinalScreen = async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`redirectToFinalScreen`);
  const state = getState();
  const finalScreen = getFinalScreen(state);
  const hash = getHash(state);
  const registrationStatus = getRegistrationStatus(state);
  const statusIsSubmitted = SUBMITTED_STATUSES.includes(registrationStatus);
  if (!finalScreen && statusIsSubmitted) {
    // Legacy: support flows without final screen (only for submitted status)
    dispatch(setIsRedirecting(true));
    const redirectLink = getLegacySignupLink(hash, 6);
    await new Promise((res) => setTimeout(res, 1000));
    window.location.replace(redirectLink);
  } else {
    dispatch(replace(`/driver/final/${window.location.search}`));
  }
};

export const loadRegistrationStatus = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`loadRegistrationStatus`);
  const state = getState();
  const hash = getHash(state);
  const response = await registrationApi.getDriverRegistrationStatus({
    hash,
  });
  dispatch(setRegistrationStatus(response.status, response.decline_reasons));
};

export const redirectToFinalScreenIfNeeded = async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`redirectToFinalScreenIfNeeded`);
  const state = getState();
  const registrationStatus = getRegistrationStatus(state);

  if (DECLINED_STATUSES.includes(registrationStatus)) {
    // Call separate endpoint that retrieves also decline reason
    await loadRegistrationStatus(dispatch, getState);
  }

  if (FINAL_STATUSES.includes(registrationStatus)) {
    await redirectToFinalScreen(dispatch, getState);
  }
};

export const loadSupportedLanguages = async (
  dispatch: Dispatch<any>,
  country?: string
): Promise<void> => {
  const actualCountry = country ?? DEFAULT_COUNTRY;
  Logger.log(`loadSupportedLanguages country=${actualCountry}`);

  try {
    const response = await registrationApi.getSupportedLanguages({
      country: actualCountry,
    });

    if (response) {
      dispatch(setLanguages(response.languages));
    }
  } catch (err) {
    dispatch(setLanguages(getMockSupportedLanguagesResponse().languages));
  } finally {
    dispatch(setLanguageLoadingStatus(LanguageLoadingStatus.READY));
  }
};

export const loadCommonTranslations = async (
  dispatch: Dispatch<any>,
  country?: string,
  language?: string
): Promise<void> => {
  Logger.log(`loadCommonTranslations country=${country} language=${language}`);
  try {
    const response = await registrationApi.getCommonTranslations({
      language,
      country,
    });

    if (response) {
      dispatch(setCommonTranslations(response.common_translations));
    }
  } catch (err) {
    dispatch(setCommonTranslations(getMockCommonTranslations()));
  }
};

export const loadCaptureMode = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`loadCaptureMode`);
  try {
    const hash = getHash(getState());
    const appVersion = getAppVersionFromQuery() ?? undefined;
    const hasPartnerAccount = getHasPartnerAccount(getState());
    if (hasPartnerAccount && appVersion) {
      const captureMode = await partnerRegistrationApi.getCaptureMode({
        hash,
        app_version: appVersion,
      });
      if (captureMode) {
        dispatch(setCaptureModeLoaded(captureMode));
      }
    }
  } catch (e) {
    // ignoring
  }
};

export const loadFormData = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> => {
  const hash = getHash(getState());
  const language = getLanguage(getState());

  Logger.log(`loadFormData hash=${hash}`);

  let response: DriverRegistrationPortalNoAuthService.GetFieldsResponse | null =
    null;
  try {
    response = await registrationApi.getFields({ hash, language });
  } catch (error) {
    if (
      isErrorResponse(error) &&
      error.response.code === ResponseCodes.NO_FLOW_ASSIGNED
    ) {
      dispatch(setIsRedirecting(true));
      window.location.replace(getLegacySignupLink(hash));
      return;
    }
    throw error;
  }

  if (response) {
    if (!ALLOWED_STATUSES.includes(response.driver_registration_log_status)) {
      throw new Error('Registration status not supported');
    }

    dispatch(formLoaded(response));

    if (response.country) {
      dispatch(setCountry(response.country));
      dispatch(setIsCountryFinal(true));
    }

    LocalStorage.setItem(LocalStorage.LanguageKey, language);
    dispatch(setLanguage(response.language));

    GoogleAnalytics.identify(hash);

    await redirectToFinalScreenIfNeeded(dispatch, getState);

    const isStepEmpty = getIsCurrentStepEmpty(getState());
    if (isStepEmpty) {
      const step = getCurrentStep(getState());
      dispatch(postForm(step, true));
    }
  }
};

export const getCountryFromIpLocation = async (): Promise<string> => {
  Logger.log(`getCountryFromIpLocation`);
  let country = DEFAULT_COUNTRY;
  try {
    const geoApiResponse = await geoApiClient.getIpLocation();

    if (geoApiResponse.data.country_iso_code) {
      country = geoApiResponse.data.country_iso_code;
    }
  } catch (ignored) {
    // ignored
  }
  return country;
};

export const loadRegistrationFlow = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> => {
  const hash = getHash(getState());
  Logger.log(`loadRegistrationFlow hash=${hash}`);
  if (hash) {
    try {
      await Promise.all([
        loadFormData(dispatch, getState),
        loadCaptureMode(dispatch, getState),
      ]);
      dispatch(setLoadingStatus(LoadingStatus.READY));
    } catch (err) {
      handleErrorResponse(err, dispatch);
      dispatch(setLoadingStatus(LoadingStatus.FAILED));
    }
  } else {
    dispatch(setLoadingStatus(LoadingStatus.FAILED));
  }
};

export const setupAndGetCountry = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<string> => {
  let stateCountry;
  const queryCountry = getCountryFromQuery();
  const storedCountry = LocalStorage.getItem(LocalStorage.CountryKey);

  const isCountryFinal = getIsCountryFinal(getState());
  if (isCountryFinal) {
    stateCountry = getCountry(getState());
  }

  let country = stateCountry || queryCountry || storedCountry;
  Logger.log('setupAndGetCountry', {
    country,
    stateCountry,
    queryCountry,
    storedCountry,
    isCountryFinal,
  });

  if (!country) {
    country = await getCountryFromIpLocation();
  }

  dispatch(setCountry(country));

  return country;
};

export const saveAppVersionToCookies = (): void => {
  const APP_VERSION_COOKIE_MAX_AGE = 14 * 24 * 60 * 60; // 14 days
  const appVersion = getAppVersionFromQuery();
  if (appVersion) {
    setCookie('app_version', appVersion, {
      'max-age': APP_VERSION_COOKIE_MAX_AGE,
    });
  }
};
