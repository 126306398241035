import React, { ChangeEvent, useRef } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FieldLabel } from './FieldLabel';
import ErrorHint from '../common/ErrorHint';
import FieldDescription from '../common/FieldDescription';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';
import { getHash, getTranslations } from '../../redux/form/formSelectors';
import driverRegistrationApiClient from '../../api/DriverRegistrationApi';
import {
  getCountry,
  getLanguage,
} from '../../redux/localization/localizationSelectors';

interface TextboxProps {
  field: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
  blurHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
}

const MIN_SEARCH_LENGTH = 5;
const DEBOUNCE_DELAY_MS = 700;

export const StructuredAddress: React.FC<TextboxProps> = ({
  field,
  updateHandler,
  blurHandler,
}) => {
  const hash = useSelector(getHash);

  const latestRequestRef = useRef<number>(0);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [searchString, setSearchString] = React.useState(
    field.current_value?.fallback_address ?? ''
  );
  const [searchResults, setSearchResults] = React.useState<
    DriverRegistrationPortalNoAuthService.AddressComponentsWithId[]
  >([]);
  const [selectedResult, setSelectedResult] =
    React.useState<DriverRegistrationPortalNoAuthService.StructuredAddress | null>(
      (field.current_value as DriverRegistrationPortalNoAuthService.StructuredAddress) ??
        null
    );
  const [apartmentNumber, setApartmentNumber] = React.useState<string | null>(
    field.current_value?.apartment_number || null
  );
  const commonTranslations = useSelector(getTranslations);
  const apartmentNumberPlaceholder =
    commonTranslations.address.apartment_number_placeholder;
  const country = useSelector(getCountry);
  const language = useSelector(getLanguage);

  const updateFromSelect = React.useCallback(
    (result: DriverRegistrationPortalNoAuthService.AddressComponents) => {
      const newResult = {
        country_code: result.country_code ?? null,
        postcode: result.postcode ?? null,
        city_name: result.city ?? null,
        street_name: result.street_name ?? null,
        building_number: result.street_number ?? null,
        flat_number: apartmentNumber ?? null,
        suburb: result.country_subdivision ?? null,
        fallback_address: result.fallback_address ?? '',
      };
      setSelectedResult(newResult);
      updateHandler(newResult);
    },
    [apartmentNumber, updateHandler]
  );

  const updateFromApartment = React.useCallback(
    (aptNumber: string | null) => {
      const blankAddress = {
        country_code: null,
        postcode: null,
        city_name: null,
        street_name: null,
        building_number: null,
        suburb: null,
        fallback_address: '',
      };
      const newResult = {
        ...blankAddress,
        ...selectedResult,
        flat_number: aptNumber,
      };
      setSelectedResult(newResult);
      updateHandler(newResult);
    },
    [selectedResult, updateHandler]
  );

  const handleSelect = React.useCallback(
    async (
      result: DriverRegistrationPortalNoAuthService.AddressComponentsWithId
    ) => {
      try {
        const components = await driverRegistrationApiClient.getAddressDetails({
          hash,
          field_key: field.name,
          place_id: result.place_id,
          language,
        });

        setSearchString(result.fallback_address);
        updateFromSelect(components);
        setSearchResults([]);
      } catch (error) {
        // Ignore, hopefully user clicks again
      }
    },
    [field.name, hash, updateFromSelect, language]
  );

  const doSearch = React.useCallback(
    async (query: string) => {
      latestRequestRef.current += 1;
      const currentRequest = latestRequestRef.current;

      updateFromSelect({
        fallback_address: query,
      });

      try {
        const results = (
          await driverRegistrationApiClient.searchAddress({
            query,
            language,
            hash,
            field_key: field.name,
            country_code: country,
          })
        ).list;
        const filteredResults = results.filter(
          (result) => !result.country_code || result.country_code === country
        );

        if (currentRequest === latestRequestRef.current) {
          setSearchResults(filteredResults);
        }
      } catch (error) {
        // ignore, hopefully next request will succeed
      }
    },
    [field.name, hash, updateFromSelect, country, language]
  );
  const debouncedSearch = (query: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      doSearch(query);
    }, DEBOUNCE_DELAY_MS);
  };

  const onChangeAddressSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    setSearchString(e.target.value);
    if (str.length < MIN_SEARCH_LENGTH) {
      setSearchResults([]);
      return;
    }
    debouncedSearch(e.target.value);
  };

  const { translations, name, is_required, current_value } = field;
  const { label, description, placeholder } = translations;

  const error = useSelector(getFieldError(name));

  const borderColorClassName = error
    ? 'border-red-500'
    : 'border-gray-200 focus:border-green-500';

  const bgClassName = error ? 'bg-white' : 'bg-gray-200';

  const onChangeApartmentNumber = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const aptNumber = e.target.value.length ? e.target.value : null;
      setApartmentNumber(aptNumber);
      if (selectedResult) {
        setSelectedResult({
          ...selectedResult,
          flat_number: aptNumber,
        });
      }
      updateFromApartment(aptNumber);
    },
    [selectedResult, updateFromApartment]
  );

  return (
    <div className="w-full">
      <FieldLabel text={label} isRequired={is_required} />
      <div className="w-full my-2">
        <input
          id={label}
          data-test={`input_${label}`}
          className={`outline-none h-12 p-3 border-2 placeholder-gray-700 rounded focus:bg-white ${borderColorClassName} ${bgClassName} w-full`}
          placeholder={placeholder}
          value={searchString}
          onChange={onChangeAddressSearch}
          onBlur={(e) => {
            blurHandler(e);
          }}
        />
        {searchResults.length > 0 && (
          <div className="bg-white w-full border border-gray-300 rounded mt-1">
            {searchResults.map((result) => (
              <div
                role="presentation"
                key={result.fallback_address}
                className="p-2 hover:bg-gray-100"
                onClick={() => handleSelect(result)}
                onKeyDown={() => {
                  handleSelect(result);
                }}
              >
                {result.fallback_address}
              </div>
            ))}
          </div>
        )}
        {field.current_value && (
          <input
            id={`${label}_apartment`}
            data-test={`input_${label}_apartment`}
            className={`outline-none h-12 mt-3 p-3 border-2 placeholder-gray-700 rounded focus:bg-white ${borderColorClassName} ${bgClassName} w-full`}
            placeholder={apartmentNumberPlaceholder}
            defaultValue={
              current_value === null || !current_value.apartment_number
                ? ''
                : current_value.apartment_number
            }
            onChange={onChangeApartmentNumber}
            onBlur={blurHandler}
          />
        )}
      </div>
      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </div>
  );
};
