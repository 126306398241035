import { Dispatch } from 'redux';
import { DriverAuthService } from '@bolteu/bolt-server-api-partner-driver-web';
import authApi from '../../api/AuthApi';
import partnerRegistrationApi from '../../api/PartnerRegistrationApi';
import { IAppState } from '../types';
import { Logger } from '../../common/logger';
import {
  clearAuthTokensFromUrl,
  getAuthTokenFromUrl,
  getHashFromCookies,
  getHashFromUrl,
  getLegacySignupLink,
  objectToQueryString,
  saveHashToCookies,
} from '../../common/helpers';
import * as LocalStorageService from '../../common/LocalStorageService';
import {
  setActualRegistrationInfo,
  setAuthMagicToken,
  setAuthTokens,
  setIsRedirecting,
} from './authActions';
import { getAccessToken, getActualRegistrationInfo } from './authSelectors';
import { setHash } from '../form/formActions';
import {
  setCountry,
  setIsCountryFinal,
} from '../localization/localizationActions';
import { IN_PROGRESS_STATUSES } from '../../const/statuses';
import partnerDriverAuthApi from '../../api/PartnerDriverAuthApi';
import { getLinkToVehicleMarketplace } from '../../common/environment';
import { getLanguage } from '../localization/localizationSelectors';
import { getCityId } from '../form/formSelectors';

async function clearAuthentication(dispatch: Dispatch) {
  dispatch(setAuthTokens(null, null));
  LocalStorageService.setItem(LocalStorageService.refreshTokenKey, '');
}

export function setRegistrationHash(
  dispatch: Dispatch,
  getState: () => IAppState
): void {
  Logger.log(`setRegistrationHash`);

  const actualRegistration = getActualRegistrationInfo(getState());
  const queryHash = getHashFromUrl();
  const cookieHash = getHashFromCookies();
  const isLocalhost = window.location.host.indexOf('localhost') !== -1;
  if (actualRegistration && actualRegistration.hash) {
    Logger.log(`setRegistrationHash - use actualRegistration`);
    saveHashToCookies(actualRegistration.hash);
    dispatch(setHash(actualRegistration.hash));
  } else if (queryHash) {
    Logger.log(`setRegistrationHash - use queryHash`);
    saveHashToCookies(queryHash);
    dispatch(setHash(queryHash));
  } else if (cookieHash) {
    Logger.log(`setRegistrationHash - use cookieHash`);
    saveHashToCookies(cookieHash);
    dispatch(setHash(cookieHash));
  } else if (isLocalhost) {
    const testHash = `H${'A'.repeat(37)}SH`;
    dispatch(setHash(testHash));
  } else {
    Logger.log(`setRegistrationHash - not found`);
  }
}

async function loadActualRegistrationInfo(
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> {
  Logger.log(`loadActualRegistrationInfo`);

  const accessToken = getAccessToken(getState());
  if (!accessToken) {
    Logger.log(`loadActualRegistrationInfo - no access token`);
    return;
  }

  const registrationInfo = await partnerRegistrationApi.getActualRegistration(
    {}
  );
  if (!registrationInfo || !registrationInfo.hash) {
    Logger.log(`loadActualRegistrationInfo - no actual registration found`);
    return;
  }

  if (registrationInfo.country) {
    dispatch(setCountry(registrationInfo.country));
    dispatch(setIsCountryFinal(true));
  }

  const hashFromUrl = getHashFromUrl();
  if (hashFromUrl && hashFromUrl !== registrationInfo.hash) {
    clearAuthentication(dispatch);
    return;
  }

  // Redirect to Driver Portal if registration is in progress,
  // but the flow is not assigned:
  if (
    IN_PROGRESS_STATUSES.includes(registrationInfo.status) &&
    !registrationInfo.flow_id
  ) {
    dispatch(setIsRedirecting(true));
    window.location.replace(getLegacySignupLink(registrationInfo.hash));
    return;
  }

  Logger.log('Dispatch setActualRegistrationInfo', {
    hash: registrationInfo.hash,
    status: registrationInfo.status,
  });
  dispatch(
    setActualRegistrationInfo(registrationInfo.hash, registrationInfo.status)
  );
}

async function setupAuthentication(
  dispatch: Dispatch,
  refreshToken: string
): Promise<void> {
  Logger.log(`setupAuthentication`);

  const data = await authApi.getAccessToken({
    refresh_token: refreshToken,
  });
  dispatch(setAuthTokens(refreshToken, data.access_token));
  LocalStorageService.setItem(
    LocalStorageService.refreshTokenKey,
    refreshToken
  );
}

export const handleAuthentication = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`handleAuthentication`);

  // There are 2 supported types of authentication:
  //   1. Partner authentication, based on auth tokens.
  //   2. Accessing registration directly using secret "hash" string.
  //
  // To work with signup form we are using "hash".
  // So we either have partner auth and retrieve "hash" using API,
  // or get "hash" directly from URL (query param or anchor).

  const refreshTokenFromUrl = getAuthTokenFromUrl();
  const savedRefreshToken = LocalStorageService.getItem(
    LocalStorageService.refreshTokenKey
  );
  const refreshToken = refreshTokenFromUrl || savedRefreshToken;

  if (refreshToken) {
    Logger.log(`handleAuthentication - refreshTokenFound`);
    try {
      await setupAuthentication(dispatch, refreshToken);
      await loadActualRegistrationInfo(dispatch, getState);
    } catch (err) {
      clearAuthentication(dispatch);
    }
  }

  setRegistrationHash(dispatch, getState);

  clearAuthTokensFromUrl();
};

export const getAuthMagicToken = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`getAuthMagicToken`);

  const accesstoken = getAccessToken(getState());
  if (!accesstoken) {
    Logger.log(`getAuthMagicToken - no access token`);
    return;
  }
  const tokenResponse = await partnerDriverAuthApi
    .driverRegistrationGenerateMagicToken()
    .catch(() => {
      return {
        token: '',
      } as DriverAuthService.GetMagicLinkTokenResponse;
    });

  dispatch(setAuthMagicToken(tokenResponse.token));
};

export const generateTokenAndRedirectToMarketplace = async (
  getState: () => IAppState
): Promise<void> => {
  Logger.log(`generateTokenAndRedirectToMarketplace`);

  const state = getState();
  const language = getLanguage(state);
  const cityId = getCityId(state);

  const queryParams = objectToQueryString({ language, city_id: cityId });

  let url = `${getLinkToVehicleMarketplace()}${queryParams}`;

  try {
    const response =
      await partnerDriverAuthApi.vehicleMarketplaceGenerateMagicToken();
    const token = response?.token;

    if (token) {
      url += `#token=${token}`;
    }
  } catch (ignored) {
    Logger.log(`generateTokenAndRedirectToMarketplace - api call failed`);
  } finally {
    window.location.replace(url);
  }
};
