import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SignupFormLayout } from './components/SignupFormLayout';
import { NotFound } from './components/NotFound';
import { VerificationWrapper } from './components/verification/VerificationWrapper';
import { saveAppVersionToCookies } from './redux/common/commonHelpers';
import { PickOtpChannel } from './components/verification/PickOtpChannel';
import { AppPromotion } from './components/app_promotion/AppPromotion';
import { RedirectIfNoPartnerDriverToken } from './components/RedirectIfNoPartnerDriverToken';
import { RiderUserSignup } from './components/rider_user_signup/RiderUserSignup';
import { Header } from './components/common/Header';

export const App: React.FC = () => {
  React.useEffect(() => {
    saveAppVersionToCookies();
  }, []);

  return (
    <>
      <Header />
      <Switch>
        <Route path="/driver">
          <Switch>
            <Route path="/driver/verification/:token">
              <VerificationWrapper />
            </Route>
            <Route path="/driver/pickOtpChannel/:token">
              <PickOtpChannel />
            </Route>
            <Route path="/driver/appPromotion/">
              <AppPromotion />
            </Route>
            <Route path="/driver/404">
              <NotFound />
            </Route>
            <Route path="/driver/authRequired">
              <RedirectIfNoPartnerDriverToken />
            </Route>
            <Route path="/driver/riderUserSignup" exact>
              <RiderUserSignup />
            </Route>
            <Route exact>
              <SignupFormLayout />
            </Route>
          </Switch>
        </Route>
        <Redirect path="*" to="/driver/404" />
      </Switch>
    </>
  );
};
